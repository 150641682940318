import './index.style.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'lazysizes';

import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import {ToastContainer} from "react-toastify";
import { changeBackgroundDarkToLight, changeBackgroundLightToDark} from './config/helper';
import ROUTES from './config/route';
import { AccountProvider } from './account';

const LazyHomePage = React.lazy(() => {
    changeBackgroundLightToDark();

    return import('./pages/Home.component');
});

const LazyBoxesPage = React.lazy(() => {
    changeBackgroundDarkToLight();

    return import('./pages/Boxes.component');
});

const root = createRoot(document.getElementById('root')!);

root.render(
    <React.Suspense fallback={<>Loading...</>}>
        <AccountProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={ ROUTES.HOME.ROUTE } element={<LazyHomePage />} />
                    <Route path={ ROUTES.BOXES.ROUTE } element={<LazyBoxesPage />} />
                </Routes>
            </BrowserRouter>
            <ToastContainer/>
        </AccountProvider>
    </React.Suspense>
);
