import { AddEthereumChainParameter } from "@web3-react/types";

const {
    REACT_APP_CHAIN_ID = "56",
    REACT_APP_CHAIN_NAME = "BNB Smart Chain",
    REACT_APP_RPC_URL = "https://bsc-dataseed.binance.org/",
    REACT_APP_EXPLORER_URL = "https://bscscan.com",
} = process.env;

export const CHAIN: AddEthereumChainParameter = {
    chainId: parseInt(REACT_APP_CHAIN_ID),
    chainName: REACT_APP_CHAIN_NAME,
    nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
    rpcUrls: [REACT_APP_RPC_URL],
    blockExplorerUrls: [REACT_APP_EXPLORER_URL]
}

export const {
    REACT_APP_LOCK_REWARD_ADDRESS: LOCK_REWARD_ADDRESS = "0x910c01cc458049bdc9689ed5139a95c132186e63",
    REACT_APP_TOKEN_ADDRESS: TOKEN_ADDRESS = "0x51e7b598c9155b9dccb04eb42519f6eec9c841e9",
    REACT_APP_REWARD_TOKEN_ADDRESS: REWARD_TOKEN_ADDRESS = "0xCae88aF5BEa2b66535b27634acfc7108cdb77742",
    REACT_APP_CLAIM_REWARD_ADDRESS: CLAIM_REWARD_ADDRESS = "0x77aA80aBab3dEe3b6c6F304b4a272c9a2816cadB",
} = process.env;

// STATIC
export const TOTAL_LOCKED = 3002500;

export enum StakeState {
    BEFORE,
    DURING,
    AFTER
}

export const STAKE_STATE = StakeState.AFTER;
export const DECIMALS = "1000000";
export const TIME_REWARD_ID = 0;
export const AMOUNT_REWARD_ID = 1;

export enum RewardId {
    TIME = TIME_REWARD_ID,
    AMOUNT = AMOUNT_REWARD_ID,
}

export const BOXES = [
    { name: "A", available: 1000, value: 100, price: 1, reward: RewardId.TIME },
    { name: "B", available: 50, value: 1000, price: 5, reward: RewardId.TIME },
    { name: "C", available: 20, value: 1500, price: 1, reward: RewardId.AMOUNT },
    { name: "D", available: 10, value: 5000, price: 2, reward: RewardId.AMOUNT },
];

export const CLAIM_CURRENCY = "BTL";

export const REWARD_NAMES = {
    [RewardId.TIME]: "Silver",
    [RewardId.AMOUNT]: "Gold",
}