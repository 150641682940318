import { initializeConnector, useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { CHAIN } from '../config';
import { PropsWithChildren } from 'react';
import { Contract, ContractInterface } from 'ethers';

export function useAccountContract(address: string, abi: ContractInterface) {
    const { provider } = useWeb3React();
    const signer = provider?.getSigner();
    return signer && new Contract(address, abi, signer);
}

const metaMaskConnector = initializeConnector((actions) => new MetaMask(actions), [CHAIN.chainId]);

export function AccountProvider({ children }: PropsWithChildren<{}>) {
    return <Web3ReactProvider connectors={[metaMaskConnector]} network={CHAIN.chainId}>{children}</Web3ReactProvider>;
}

const [metaMask] = metaMaskConnector;
const activate = () => metaMask.activate(CHAIN);
const deactivate = () => metaMask.deactivate();

export function useAccount() {
    const { account } = useWeb3React();
    if (!account) return { activate };
    return { account, deactivate };
}